import React, { Fragment } from 'react';
import Navbar from '../../components/LauraAndree/Navbar'
import Hero from '../../components/LauraAndree/HeroBoda';
import WeddingDate from '../../components/LauraAndree/WeddingDate';
import CoupleSection from '../../components/LauraAndree/CoupleSection';
import VideoSection from '../../components/LauraAndree/VideoSection';
import StorySection from '../../components/LauraAndree/StorySection';
import BrideGrooms from '../../components/LauraAndree/BrideGrooms';
import ProjectSinglePage from '../../components/LauraAndree/DetailsFragment';
import EventSection from '../../components/LauraAndree/EventSection';
import RsvpSection from '../../components/LauraAndree/RsvpSection';
import PortfolioSection from '../../components/LauraAndree/PortfolioSection';
import Footer from '../../components/LauraAndree/Footer';
import Scrollbar from '../../components/LauraAndree/scrollbar/scrollbar';
import vImg from '../../images/lauraYandree/Save-the-Date-Card.png'
import imgDetailsText from '../../images/lauraYandree/itinerario.jpeg'
import bg from '../../images/lauraYandree/Save-the-Date-Card.png'

const HomePage = () => {

    return (
        <Fragment>
            <Navbar />
            <Hero />
            <WeddingDate pbClass={'pb-0'} WeddingDate={'12/14/2024'}/>
            <CoupleSection />
            <VideoSection vimg={vImg}/>
            <StorySection/>
            <BrideGrooms/>
            <EventSection/>
            <RsvpSection bg={bg}/>
            <ProjectSinglePage titleDetail={'Los últimos detalles'} imgDetail={imgDetailsText}/>
            {/* <PortfolioSection /> */}
            <Footer />           
            <Scrollbar /> 
        </Fragment>
    )
};

export default HomePage;