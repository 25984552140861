import React from 'react';
import coupleImg1 from '../../images/SaveTheDate/4.JPG'
import coupleImg2 from '../../images/icon/lya-centro.svg'
import vector1 from '../../images/icon/lya-pikachu-he-color.svg'
import vector2 from '../../images/icon/lya-pikachu-she-color.svg'

const CoupleSection = (props) => {

    return (
        <section className="wpo-couple-section-s3 section-padding" id="couple">
            <div className="container-fluid">
                <div className="couple-area clearfix">
                    <div className="row align-items-center">
                        <div className="col col-xl-3 col-lg-4 col-12">
                            <div className="text-grid">
                                <div className="vector">
                                    <img src={vector1} alt="" />
                                </div>
                                <h3 className="playfair-display-names">ANDREE:</h3>
                                <p>Con el corazón lleno de alegría y emoción, nos embarcamos en esta nueva aventura.
                                   Nuestro amor, lealtad y compromiso 
                                   nos han llevado a este día tan especial.</p>
                                <div className="social">
                                    <ul>
                                        <li><a href="https://www.facebook.com/andreegio"><i className="ti-facebook"></i></a></li>                                        
                                        <li><a href="https://www.instagram.com/andreegiovanny/"><i className="ti-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-6 col-lg-4 col-12">
                            <div className="middle-couple-pic">
                                <div className="middle-couple-pic-inner">
                                    <img src={coupleImg1} alt="" />
                                </div>
                                <div className="couple-flower">
                                    <img src={coupleImg2} alt="" className="img-center" />
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-3 col-lg-4 col-12">
                            <div className="text-grid">
                                <div className="vector">
                                    <img src={vector2} alt="" />
                                </div>
                                <h3 className="playfair-display-names">LAURA:</h3>
                                <p>Con una sonrisa que ilumina nuestros pasos, caminamos hacia este gran día
                                    Y con el corazón lleno de ternura y esperanza,
                                   nos preparamos para ser compañeros y confidentes en este viaje de amor.</p>
                                <div className="social">
                                    <ul>
                                        <li><a href="https://www.facebook.com/Gadget0100110"><i className="ti-facebook"></i></a></li>                                        
                                        <li><a href="https://www.instagram.com/gadgetl001/"><i className="ti-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoupleSection;