import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom'
const TextoDetalles = [
    {
        title: 'ANTES DE LA CEREMONIA',
        li1: '- Te invitamos a acompañarnos puntualmente, una vez cerradas las puertas de la iglesia no se permitirá el acceso a nadie.',
        li2: ''
    },
    {
        title: 'DURANTE DE LA CEREMONIA',
        li1: '- Ayúdanos a mantener un ambiente mágico e íntimo, apagando el sonido de sus télefonos celulares.',
        li2: '- Favor de no tomar fotografías, permítenos recordas este día a través de la lente de los profesionales.'
    },
    {
        title: 'AL FINALIZAR LA MISA',
        li1: '- Pedimos su comprensión, ya que no será posible tomarnos fotografías en el altar de la iglesia.',
        li2: ''
    },
    {
        title: 'EN LA RECEPCIÓN',
        li1: '- No olvides tu pase de abordar, es tu acceso al evento',
        li2: '- Te sugerimos vestir formalmente, recuerda que esta es una noche especial, lúcete!'
    },
    {
        title: 'OTRAS RECOMENDACIONES',
        li1: '- Evitar el uso de ropa totalmente blanca, nude o rojo',
        li2: '- Tu presencia es lo  que más valoramos, para aquellos que deseen hacer un regalo adicional, un aporte financiero nos ayudaría a establecer nuestro hogar de manera significativa'
    }
]

const ProjectSinglePage = (props) => {
    const { slug } = useParams()
    return (
        <Fragment>
            <section className="wpo-portfolio-single-section section-padding">
                <div className="container">
                    <div className="portfolio-single-wrap">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="portfolio-single-img-wrap">
                                    <div className="portfolio-single-img">
                                        <img src={props.imgDetail} alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="portfolio-single-text">
                                    <h2 className="montserrat-subtitle">{props.titleDetail}</h2>
                                    <div className="">
                                        {TextoDetalles.map((event, eitem) => (
                                            <div>
                                                <span className="playfair-display-span">{event.title}</span>
                                                <p>{event.li1}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
};
export default ProjectSinglePage;
