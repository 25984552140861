import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from './MobileMenu/MobileMenu'
import { connect } from "react-redux";
import { ReactComponent as Icon } from '../../images/icon/lya-iniciales1.svg';

const Header = (props) => {
    const [menuActive, setMenuState] = useState(false)
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const { carts } = props;

    return (

        <header id="header" className={props.topbarNone}>
            <div className={`wpo-site-header ${props.hclass}`}>
                <nav className="navigation navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                                <div className="mobail-menu">
                                    <MobileMenu/>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-6">
                                <div className="navbar-header">
                                    <Link onClick={ClickHandler} className="navbar-brand" to="/">
                                        <Icon className="icon-w-80px" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-1 col-1">
                                <div id="navbar" className="collapse navbar-collapse navigation-holder">
                                    <button className="menu-close"><i className="ti-close"></i></button>
                                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                                        <li><Link onClick={ClickHandler} to="/">Inicio</Link></li>                                      
                                        <li><a href="#event">Evento</a></li>                                   
                                        <li><a href="https://api.whatsapp.com/send?phone=525649692494&text=%F0%9F%92%92%F0%9F%A4%B5%F0%9F%91%B0%0ATengo%20una%20duda%20sobre%20el%20evento">WA</a></li>     
                                        <li><a href="#rsvp">Confirmación</a></li>                                   
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}
const mapStateToProps = (state) => {
    return {
        carts: state.cartList.cart,
    };
};

export default connect(mapStateToProps)(Header);