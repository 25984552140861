import React, { useState, useEffect  } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
// const API_URL = 'http://127.0.0.1:8000/wedding/api/v1/';
const API_URL = 'https://andreegap.pythonanywhere.com/wedding/api/v1/';

const RSVPFrom = () => {
    const [forms, setForms] = useState({
        guest_id: '',
        guest_name: '', // Para mostrar el nombre del invitado seleccionado
        number_phone: '',
        tickets: 0,
        maxTickets: 1, // para limitar el número máximo de pases
        responded: false,
        rsvp: false,
        invitation_id: '', // para id de la invitacion
    });
    const [guests, setGuests] = useState([]);
    const [searchTerm, setSearchTerm] = useState('')
    // const [selectedGuest, setSelectedGuest] = useState(null); // Estado para almacenar el límite de pases
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    
    // Obtener invitados no confirmados al cargar el componente
    useEffect(() => {
        axios.get(`${API_URL}guests/unconfirmed/`)
            .then(response => {
                setGuests(response.data);
            })
            .catch(error => {
                console.error("Hubo un error al obtener los invitados", error);
            });
    }, []);

    // const changeHandler = e => {
    //     const { name, value } = e.target;

    //     if (name === 'guest_id') {
    //         const guest = guests.find(guest => guest.id === value);
    //         if (guest) {
    //             setForms(prevForms => ({
    //                 ...prevForms,
    //                 guest_id: guest.id,
    //                 guest_name: `${guest.name} ${guest.last_name}`, // Actualiza el nombre mostrado
    //                 tickets: guest.invitation ? guest.invitation.tickets.toString() : ''
    //             }));
    //             setSearchTerm(''); // Resetea el término de búsqueda una vez seleccionado
    //         }
    //     }
    //     else {
    //         setForms(prevForms => ({ ...prevForms, [name]: value }));
    //     }

    //     if (validator.allValid()) {
    //         validator.hideMessages();
    //     } else {
    //         validator.showMessages();
    //     }
    // };

    // const submitHandler = e => {
    //     e.preventDefault();
    //     // // Validar que el código ingresado coincida con el código del invitado
    //     // if (selectedGuest && forms.code !== selectedGuest.invitation.code) {
    //     //     alert("El código ingresado no coincide con el código del invitado.");
    //     //     return;
    //     // }
    //     // Validar que el número de teléfono coincida con el del invitado
    //     const guest = guests.find(g => g.id === forms.guest_id);
    //     if (guest?.number_phone !== forms.number_phone) {
    //         alert("El número de teléfono ingresado no coincide con el invitado seleccionado.");
    //         return;
    //     }

    //     if (validator.allValid()) {
    //         validator.hideMessages();
    //         const dataToSend = {
    //             guest_id: forms.guest_id,
    //             tickets: forms.tickets,
    //             responded: true,
    //             rsvp: forms.rsvp,
    //         };
            
    //         axios.put(`${API_URL}/invitations/${forms.guest_id}`, dataToSend)
    //             .then(() => {
    //                 console.log("Invitación actualizada con éxito");
    //                 setForms({
    //                     guest_id: '',
    //                     guest_name: '',
    //                     number_phone: '',
    //                     tickets: '',
    //                     responded: false,
    //                     rsvp: false,
    //                 });
    //             })
    //             .catch(error => console.error("Error al actualizar invitación", error));
    //     }
    //     else {
    //         validator.showMessages();
    //     }
    // };

    const onSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const onSelectGuest = (guest) => {
        setForms(prevForms => ({
            ...prevForms,
            guest_id: guest.id, // Guardar el ID del invitado seleccionado
            tickets: guest.tickets,
            maxTickets: guest.tickets, // Limitar el máximo de pases
            guest_name: `${guest.name} ${guest.last_name}`, // Mostrar el nombre completo en el input
            invitation_id: guest.invitation.id,
        }));  
        setSearchTerm(''); // Restablecer el campo de búsqueda


        // const selectedGuest = guests.find(g => g.full_name === guest);
        // console.log(selectedGuest);
        // if(selectedGuest)
        // {
        //     setForms(prevForms => ({
        //         ...prevForms,
        //         tickets: 1,
        //         maxTickets: selectedGuest.tickets, // Limitar el máximo de pases
        //         guest_name: `${selectedGuest.name} ${selectedGuest.last_name}`, // Mostrar el nombre completo en el input
        //         invitation_id: selectedGuest.invitation.id,
        //     }));        
        // }        
        // setSearchTerm(''); // Restablecer el campo de búsqueda
    };

    const changeHandler = (e) => {
        const { name, value } = e.target;
        // Validar que el valor de 'tickets' no exceda 'maxTickets'
        if (name === 'tickets' && Number(value) > forms.maxTickets) {
            alert(`El número máximo de pases permitidos es ${forms.maxTickets}`);
            return;
        }
        setForms(prevForms => ({ ...prevForms, [name]: value }));
    };

    const submitHandler = e => {
        e.preventDefault();
        // Validar que el número de teléfono coincida con el del invitado seleccionado
        const guest = guests.find(g => g.id === forms.guest_id);
        if (guest?.number_phone !== forms.number_phone) {
            alert("El número de teléfono ingresado no coincide con el invitado seleccionado.");
            return;
        }

        if (guest?.tickets < forms.tickets) {
            alert(`El número máximo de pases permitidos es ${forms.maxTickets}`);
            return;
        }

        if (validator.allValid()) {
            validator.hideMessages();
            const dataToSend = {
                guest_id: forms.guest_id,
                tickets: forms.tickets,
                responded: true,
                rsvp: forms.rsvp,
                id: forms.invitation_id,
            };
            
            axios.put(`${API_URL}invitations/${forms.invitation_id}/`, dataToSend)
                .then(() => {
                    // console.log("Invitación actualizada con éxito");
                    alert("Invitación actualizada con éxito.");
                    setForms({
                        guest_id: '',
                        guest_name: '',
                        number_phone: '',
                        tickets: '',
                        maxTickets: '',
                        responded: false,
                        rsvp: false,
                        invitation_id:'',
                    });
                })
                .catch(error => console.error("Error al actualizar invitación", error));
        } else {
            validator.showMessages();
        }
    };

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                    <label htmlFor="guestSelect">Selecciona un invitado:</label>
                        <input
                            type="text"
                            id="guestSearch"
                            value={searchTerm || forms.guest_name}
                            onChange={(e) => {
                                const newValue = e.target.value;
                                setSearchTerm(newValue);
                                
                                // Si el usuario borra el campo de búsqueda, limpia la selección actual
                                if (newValue === '' && forms.guest_id) {
                                    setForms({
                                        guest_id: '',
                                        guest_name: '',
                                        number_phone: '',
                                        tickets: 0,
                                        maxTickets: 1,
                                        responded: false,
                                        rsvp: false,
                                        invitation_id: '',
                                    });
                                }
                                // if(e.target.value==="")
                                // {
                                //     setForms({
                                //         guest_id: '',
                                //         guest_name: '',
                                //         number_phone: '',
                                //         tickets: '',
                                //         maxTickets: '',
                                //         responded: false,
                                //         rsvp: false,
                                //         invitation_id:'',
                                //     });   
                                // }else{
                                // setSearchTerm(e.target.value);}
                            }}                          
                            placeholder="Buscar invitado por nombre"
                            className="form-control"
                        />
                        {searchTerm && ( // Solo muestra la lista si searchTerm no está vacío
                            <div className="suggestions">
                                {guests
                                    .filter(guest =>
                                        `${guest.name} ${guest.last_name}`
                                            .toLowerCase()
                                            .includes(searchTerm.toLowerCase())
                                    )
                                    .map(guest => (
                                        <div 
                                            key={guest.id} 
                                            onClick={() => onSelectGuest(guest)}
                                            onTouchStart={() => onSelectGuest(guest)} // Para dispositivos móviles
                                            className="suggestion-item"
                                        >
                                            {guest.name} {guest.last_name}
                                        </div>
                                    ))}
                            </div>
                        )}
                        {/* <div className="suggestions">
                            {guests
                                .filter(guest =>
                                    `${guest.name} ${guest.last_name}`
                                        .toLowerCase()
                                        .includes(searchTerm.toLowerCase())
                                )
                                .map(guest => (
                                    <div 
                                        key={guest.id} 
                                        onClick={() => onSelectGuest(guest)}
                                        // onClick={() => {
                                        //     setForms(prevForms => ({
                                        //         ...prevForms,
                                        //         guest_id: guest.id,
                                        //         guest_name: `${guest.name} ${guest.last_name}`,
                                        //         tickets: guest.invitation ? guest.invitation.tickets.toString() : '',
                                        //         invitation_id: guest.invitation.id,
                                        //     }));
                                        //     setSearchTerm(''); // Restablece el término de búsqueda
                                        // }}
                                        className="suggestion-item"
                                    >
                                        {guest.name} {guest.last_name}
                                    </div>
                                ))}
                        </div> */}
                    {/* <input
                            list="guestOptions"
                            id="guestSelect"
                            name="guest_name"
                            value={searchTerm || forms.guest_name} // Usa searchTerm para buscar y forms.guest_name para mostrar el nombre
                            onChange={onSearchChange}
                            placeholder="Buscar invitado por nombre"
                            className="form-control"
                        />
                        <datalist id="guestOptions">
                            {guests
                                .filter(guest =>
                                    `${guest.name} ${guest.last_name}`
                                        .toLowerCase()
                                        .includes(searchTerm.toLowerCase())
                                )
                                .map(guest => (
                                    <option 
                                        key={guest.id}
                                        // onClick={() => onSelectGuest(guest)}
                                        value={`${guest.name} ${guest.last_name}`} // Muestra solo el nombre y apellido
                                    />
                                ))}
                        </datalist> */}
                        {validator.message('guest_id', forms.guest_id, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <label htmlFor="number_phone">Número de Teléfono:</label>
                        <input
                            type="text"
                            id="number_phone"
                            name="number_phone"
                            value={forms.number_phone}
                            onChange={changeHandler}
                            className="form-control"
                            placeholder="Número de teléfono"
                        />
                        {validator.message('number_phone', forms.number_phone, 'required|numeric|min:10,num')}                   
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="radio-buttons">
                        <p>
                            <input 
                                type="radio" 
                                id="attend" 
                                name="rsvp" 
                                onChange={() => setForms({ ...forms, rsvp: true })} 
                            />
                            <label htmlFor="attend">Sí, con gusto!</label>
                        </p>
                        <p>
                            <input 
                                type="radio" 
                                id="not" 
                                name="rsvp" 
                                onChange={() => setForms({ ...forms, rsvp: false })} 
                            />
                            <label htmlFor="not">No, lo siento</label>
                        </p>
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <label htmlFor='tickets'>Número de pases:</label>
                        <input
                            type="number"
                            name="tickets"
                            value={forms.tickets}
                            onChange={(e) => setForms({ ...forms, tickets: e.target.value })}
                            className="form-control"
                            max= {forms.maxTickets}
                            min="1" // Asegúrate de que el mínimo sea 1
                        />
                        {validator.message('tickets', forms.tickets, 'required|numeric')}
                    </div>
                    <input
                            type="text"
                            id="invitation_id"
                            name="invitation_id"
                            value={forms.invitation_id}
                            onChange={changeHandler}
                            hidden
                            readOnly
                        />
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">Confirmar</button>
            </div>
        </form >
    )
}

export default RSVPFrom;