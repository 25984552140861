import React from 'react'
import sImg1 from '../../images/lauraYandree/foto-08-10-2018.jpeg'
import sImg2 from '../../images/lauraYandree/lauyandree1.jpg'
import sImg3 from '../../images/lauraYandree/IMG_3042.HEIC'
import { Slide } from "react-awesome-reveal";

import shape1 from '../../images/story/s-shape1.png'
import shape2 from '../../images/story/s-shape2.png'
import SectionTitle from './SectionTitle'

const Storys = [
    {
        sImg: sImg1,
        shape: shape1,
        animation: '1000',
        title: 'Antes de todo',
        date: '2017',
        description: 'Consectetur adipiscing elit. Fringilla at risus orci, tempus facilisi sed. Enim tortor, faucibus netus orci donec volutpat adipiscing. Sit condimentum elit convallis libero.',
    },
    {
        sImg: sImg2,
        shape: shape1,
        animation: '1200',
        title: 'Amor y aventuras',
        date: '2020',
        description: 'Consectetur adipiscing elit. Fringilla at risus orci, tempus facilisi sed. Enim tortor, faucibus netus orci donec volutpat adipiscing. Sit condimentum elit convallis libero.',
        order1: 'order-lg-2 order-1',
        order2: 'order-lg-1 order-2',
    },
    {
        sImg: sImg3,
        shape: shape1,
        animation: '1400',
        title: 'Una vida por delante',
        date: '2024',
        description: 'Consectetur adipiscing elit. Fringilla at risus orci, tempus facilisi sed. Enim tortor, faucibus netus orci donec volutpat adipiscing. Sit condimentum elit convallis libero.',
    }

]

const StorySection = (props) => {
    return (

        <section className="wpo-story-section-s6 section-padding" id="story">
            <div className="container-fluid">
                <div className="wpo-story-wrap">
                <SectionTitle subTitle={'Te buscaré por mil mundos durante diez mil vidas hasta encontrarte'} MainTitle={'NOSOTROS'} />
                    <div className="row">
                        {
                            Storys.map((story, st) => (
                                <div className="col col-lg-4 col-md-6 col-12" key={st}>
                                    <Slide direction="up" duration={story.animation} triggerOnce="true">
                                        <div className="wpo-story-item">
                                            <div className="wpo-story-img">
                                                <div className="wpo-story-img-inner">
                                                    <img src={story.sImg} alt="" />
                                                </div>
                                                <div className="flower-shape">
                                                    <img src={story.shape} alt="" />
                                                </div>
                                            </div>
                                            <div className="wpo-story-content">
                                                {/* <span>{story.date}</span> */}
                                                <h2 className="playfair-display-span">{story.title}</h2>
                                                {/* <p>{story.description}</p> */}
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}
export default StorySection;