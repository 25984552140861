import React from 'react'
import { Slide } from "react-awesome-reveal";
import { Link } from 'react-router-dom'
import shape1 from '../../images/icon/lya-pikahebn.svg'
import shape2 from '../../images/icon/lya-pikashebn.svg'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Footer = (props) => {
    return (
        <footer className={`wpo-site-footer ${props.footerClass}`}>
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3 className="playfair-display-details">Links</h3>
                                </div>
                                <div className="link-wrap">
                                    <ul>
                                        <li><Link onClick={ClickHandler} to="/">Alojamientos</Link></li>
                                        <li><Link onClick={ClickHandler} to="/">¿Qué hacer?</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-xl-6 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <Link className='logo' onClick={ClickHandler} to="/">L & A</Link>
                                </div>
                                <ul>
                                    <li>
                                        <a href="https://api.whatsapp.com/send?phone=9512888966&text=%F0%9F%92%92%F0%9F%A4%B5%F0%9F%91%B0%0ATengo%20una%20duda%20sobre%20el%20evento">
                                            <i className="ti-mobile"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://api.whatsapp.com/send?phone=525649692494&text=%F0%9F%92%92%F0%9F%A4%B5%F0%9F%91%B0%0ATengo%20una%20duda%20sobre%20el%20evento">
                                            <i className="ti-mobile"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3 className="playfair-display-details">Contáctanos </h3>
                                </div>
                                <div className="contact-ft">
                                    <p>nuestraboda@laurayandree.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright"> &copy; Copyright 20243 | <Link onClick={ClickHandler} to="/">L&A</Link> | Todos los derechos reservados.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ft-shape-1">
                <Slide direction="left" duration="1000" triggerOnce="true">
                    <img className='w-400' src={shape1} alt="" />
                </Slide>
            </div>
            <div className="ft-shape-2">
                <Slide direction="right" duration="1200" triggerOnce="true">
                    <img className='w-400' src={shape2} alt="" />
                </Slide>
            </div>
        </footer>
    )
}

export default Footer;