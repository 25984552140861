import React, { useState, useEffect  } from 'react'
import SimpleReactValidator from 'simple-react-validator';


const RSVPFrom = () => {

    const [forms, setForms] = useState({
        name: '',
        code: '',
        date: '',
        guest: '',
        reason: '',
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            validator.hideMessages();
            setForms({
                name: '',
                code: '',
                date: '',
                guest: '',
                reason: '',
            })
        } else {
            validator.showMessages();
        }
    };


    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="Nombre" />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="text"
                            name="code"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="Código" />
                        {validator.message('code', forms.code, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="radio-buttons">
                        <p>
                            <input type="radio" id="attend" name="radio-group" defaultChecked/>
                                <label htmlFor="attend">Sí, con gusto!</label>
                        </p>
                        <p>
                            <input type="radio" id="not" name="radio-group"/>
                            <label htmlFor="not">No, lo siento</label>
                        </p>
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.guest}
                            type="text"
                            className="form-control"
                            name="guest">
                            <option>Pases</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                        </select>
                        {validator.message('guest', forms.guest, 'required')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="form-field">
                        <input
                            value={forms.reason}
                            type="text"
                            name="reason"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            className="form-control"
                            placeholder="Otro detalle" />
                            {validator.message('reason', forms.reason, '')}
                    </div>
                </div>
            </div>
            <div className="submit-area">
                <button type="submit" className="theme-btn">Confirmar</button>
            </div>
        </form >
    )
}

export default RSVPFrom;