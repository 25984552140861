import React from 'react'
import sImg1 from '../../images/lauraYandree/lya-colitas.png'
import sImg2 from '../../images/lauraYandree/esquina-arriba.png'
import sImg3 from '../../images/lauraYandree/esquina-abajo.png'
import TimeCountDown from './countdown'
import sImgLetras from '../../images/lauraYandree/lya-letrasdorado1.png'

const WeddingDate = (props) => {

    return (
        <section className="wpo-hero-wedding-date">
            <div className="wpo-wedding-date-inner">
                <span>¡Acompáñanos en este gran día!</span>
                <img src={sImgLetras} alt="laura y andree" className="p-letras-doradas" />
                <p>¡El amor está en el aire y queremos compartirlo contigo! Únete a nuestra celebración el 14 de Diciembre de 2024</p>
                <span className="shape"><img src={sImg1} alt="icon-colitas" className="h-10rem" /> </span>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="clock-grids">
                            <div id="clock">
                                <TimeCountDown WeddingDate={props.WeddingDate}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-1">
                <img className="w-700" src={sImg2} alt="" />
            </div>
            <div className="shape-2">
                <img className="w-700" src={sImg3} alt="" />
            </div>
        </section>
    )
}

export default WeddingDate;