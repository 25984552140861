import React from "react";
import { Slide } from "react-awesome-reveal";
import SectionTitle from "./SectionTitle";
import timg1 from '../../images/lauraYandree/maricela.JPG'
import timg2 from '../../images/lauraYandree/julio.jpg'
import timg3 from '../../images/lauraYandree/judit.jpg'
import timg4 from '../../images/lauraYandree/joel.jpg'
import timg5 from '../../images/lauraYandree/aracelis.jpg'
import timg6 from '../../images/lauraYandree/fernando.jpg'

const Brides = [
  {
    id: '1',
    tImg: timg1,
    name: 'MARICELA',
    title: 'Madre del novio',
    animation: '1000',
  },
  {
    id: '2',
    tImg: timg2,
    name: 'JULIO',
    title: 'Padre del novio',
    animation: '1400',
  },
  {
    id: '3',
    tImg: timg3,
    name: 'JUDIT',
    title: 'Madre de la novia',
    animation: '1400',
  },
  {
    id: '4',
    tImg: timg4,
    name: 'JOEL',
    title: 'Padre de la novia',
    animation: '1400',
  },
  {
    id: '5',
    tImg: timg5,
    name: 'ARACELIS',
    title: 'Madrina',
    animation: '1400',
  },
  {
    id: '6',
    tImg: timg6,
    name: 'FERNANDO',
    title: 'Padrino',
    animation: '1400',
  }
]

const BrideGrooms = (props) => {
  return (
    <section className="wpo-team-section-s2 section-padding" id="personas-importantes">
      <div className="container">
        <SectionTitle subTitle={'Personas Importantes'} MainTitle={'PADRES & PADRINOS'} />
        <div className="wpo-team-wrap scene-2">
          <div className="row">
            {Brides.slice(0, 6).map((Bride, tm) => (
              <div className="layer col col-lg-2 col-md-4 col-12" key={tm}>
                <Slide direction="up" duration={Bride.animation} triggerOnce="true">
                  <div className="wpo-team-item">
                    <div className="wpo-team-img-wrap">
                      <div className="wpo-team-img">
                        <div className="wpo-team-img-inner">
                          <img src={Bride.tImg} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="wpo-team-text">
                      <h3 className="playfair-display-details">{Bride.name}</h3>
                      <span>{Bride.title}</span>
                    </div>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>

  )
}

export default BrideGrooms;